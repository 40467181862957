<template>
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link
              :to="{
                name: 'yearly-training-plan-index',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Yearly Training Plan</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="overflow: scroll">
            <div style="height: 70px">
              <!-- <div
                class="d-flex float-start"
                v-if="resData.current_budget_year"
              > -->
              <div class="d-flex float-start">
                <label for="employee_type" class="col-form-label me-2"
                  >Employee Type</label
                >
                <div style="width: 160px">
                  <v-select
                    label="name"
                    :options="employee_types"
                    :reduce="(name) => name.id"
                    v-model="employee_type"
                    @option:selected="getType($event)"
                    :clearable="false"
                  >
                  </v-select>
                </div>
                <div @click="refreshData" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
              <div class="d-flex float-end" v-if="resData.current_budget_year">
                <router-link
                  :to="{
                    name: 'yearly-training-plan-create',
                    params: { id: this.$route.params.id },
                  }"
                >
                  <button
                    type="button"
                    class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  >
                    <i class="mdi mdi-plus-circle"></i>
                    Add Plan
                  </button>
                </router-link>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>

            <table
              class="table table-responsive table-bordered mb-3"
              v-if="!loading"
            >
              <thead>
                <tr>
                  <th style="width: 16%">Months</th>
                  <th
                    v-for="(week, week_key) in resData.weeks"
                    :key="week_key"
                    scope="col"
                    style="width: 20%"
                  >
                    {{ week }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(month, month_key) in resData.months"
                  :key="month_key"
                >
                  <th scope="row">{{ month }}</th>
                  <td v-for="(week, week_key) in resData.weeks" :key="week_key">
                    <PlanList
                      :month_key="month_key"
                      :week_key="week_key"
                      :plans="filterPlans"
                      @showModalData="showModalData"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <EnrollModal :modalId="'show-plan-modal'">
    <template v-slot:header> Plan Detail </template>
    <template v-slot:body>
      <div class="card-body" style="padding: 0px !important">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap table-hover mb-0">
            <tbody>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Module Name:</span>
                </th>
                <td>
                  <span class="ms-1">{{ module_name }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Employee Type: </span>
                </th>
                <td>
                  <span v-if="employee_type_id == 1" class="ms-1">Program</span>
                  <span v-if="employee_type_id == 2" class="ms-1">Normal</span>
                </td>
              </tr>
              <tr class="custom-tr" v-if="employee_type_id == 2">
                <th>
                  <span class="ms-1">Position Level:</span>
                </th>
                <td>
                  <span class="ms-1">{{ position_level_name }}</span>
                </td>
              </tr>
              <tr class="custom-tr" v-if="employee_type_id == 1">
                <th>
                  <span class="ms-1">Program Name:</span>
                </th>
                <td>
                  <span class="ms-1">{{ program_name }}</span>
                </td>
              </tr>
              <tr class="custom-tr" v-if="employee_type_id == 1">
                <th>
                  <span class="ms-1">Level:</span>
                </th>
                <td>
                  <span class="ms-1">{{ level_name }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Month:</span>
                </th>
                <td>
                  <span class="ms-1">{{ month }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Week:</span>
                </th>
                <td>
                  <span class="ms-1">{{ week }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center mx-auto">
        <button
          type="button"
          class="btn w-sm btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
      </div>
    </template>
  </EnrollModal>
</template>

<script>
import axios from "axios";
import PlanList from "./PlanList.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import EnrollModal from "../../components/shared/Modal.vue";
import $ from "jquery";

export default {
  components: {
    vSelect,
    PlanList,
    EnrollModal,
  },
  data() {
    return {
      resData: [],
      filterPlans: [],
      monthsData: [],
      weeksData: [],
      employee_types: [],
      employee_type: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      loading: false,
      position_levels: [],
      programs: [],
      levels: [],
      module_name: "",
      month: "",
      week: "",
      employee_type_id: "",
      position_level_name: "",
      program_name: "",
      level_name: "",
    };
  },
  methods: {
    getPositionLevels() {
      axios
        .get(`${this.baseUrl}admin/v1/position-level`)
        .then((response) => {
          this.position_levels = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Position Levels!");
        });
    },
    getPrograms() {
      axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    showModalData(data) {
      this.module_name = data.name;
      // this.month = data.month;
      this.monthsData.filter((item) => {
        if (item.id == data.month) {
          this.month = item.name;
        }
      });
      // this.week = data.week;
      this.weeksData.filter((item) => {
        if (item.id == data.week) {
          this.week = item.name;
        }
      });
      this.employee_type_id = data.employee_type_id;
      this.level_name = data.level;

      this.programs.filter((item) => {
        if (data.program_id == item.id) {
          this.program_name = item.name;
        }
      });

      this.position_levels.filter((item) => {
        if (data.position_level == item.id) {
          this.position_level_name = item.name;
        }
      });

      $("#show-plan-modal").modal("show");
    },
    async getYearlyTrainingPlan() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/yearly-training-plans/${this.$route.params.id}`
        )
        .then((response) => {
          this.resData = response.data.data;
          this.filterPlans = this.resData.plans;

          let obj = this.resData.months;
          this.monthsData = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });

          let week = this.resData.weeks;
          this.weeksData = Object.keys(week).map((key) => {
            return { id: key, name: week[key] };
          });

          this.loading = false;
          this.$Progress.finish();
        });
    },
    async getEmployeeTypes() {
      await axios
        .get(`${this.baseUrl}admin/v1/employee-types`)
        .then((response) => {
          this.employee_types = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Employee Type");
        });
    },
    getType(value) {
      this.filterPlans = this.resData.plans.filter((item) => {
        if (value.id == item.employee_type_id) {
          return item;
        }
      });
    },
    refreshData() {
      this.employee_type = "";
      this.filterPlans = [];
      this.getPositionLevels();
      this.getPrograms();
      this.getEmployeeTypes();
      this.getYearlyTrainingPlan();
    },
  },
  created() {
    this.getPositionLevels();
    this.getPrograms();
    this.getEmployeeTypes();
    this.getYearlyTrainingPlan();
  },
};
</script>

<style scoped>
.custom-tr {
  border-style: hidden !important;
}
</style>
